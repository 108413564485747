<template>
  <div class="tools">
    <el-form style="padding:50rem;">
      <div class="data">
        <!--        原始数据-->
        <div class="item flex flex-between" v-for="(item,index) in coach" :key="'coach_'+index">
          <div style="width: calc(100% - 100rem);padding-right: 30rem">
            <div class="flex flex-between">
              <el-form-item label="校区">{{item.school_name}}</el-form-item>
              <el-form-item label="水印颜色">
                <span
                    style="display: inline-block;width: 20rem;height: 20rem;margin-right: 10rem;transform: translateY(4rem)"
                    :style="{background: item.color}"></span>
                {{item.color}}
              </el-form-item>
              <el-form-item label="水印透明度">{{item.opacity}}</el-form-item>
<!--              <el-form-item label="水印开关">{{item.on_off == 1 ? '开' : '关'}}</el-form-item>-->
              <el-form-item label="水印开关" size="mini">
                <el-switch v-model="coach[index].on_off" :active-value="1" :inactive-value="2"></el-switch>
              </el-form-item>
            </div>
            <div class="flex flex-between">
              <el-form-item label="水印字号">{{item.font_size}}</el-form-item>
              <el-form-item label="水印纵向间距">{{item.portrait}}</el-form-item>
              <el-form-item label="水印横向间距">{{item.transverse}}</el-form-item>
            </div>
          </div>
          <div class="handle">
            <div class="add" v-if="showAdd(index,0)" @click="onAdd(index,0)"><i class="el-icon-plus"></i></div>
            <div class="remove" v-if="showRemove(index,0)" @click="onRemove(index,0)"><i class="el-icon-delete"></i></div>
          </div>
        </div>
        <!--        新增数据-->
        <div class="item" v-for="(item,index) in coachAdd" :key="'coach_add_'+index">
          <div style="width: calc(100% - 100rem);padding-right: 30rem">
            <div class="flex flex-between">
              <el-form-item label="校区" size="mini">
                <el-select placeholder="请选择校区" v-model="coachAdd[index].school_id" @change="onChangeSchool($event,index)" ref="mySelect">
                  <el-option
                      v-for="item in schoolList"
                      :key="item.id"
                      :label="item.school_name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="水印颜色" size="mini">
                <el-color-picker v-model="coachAdd[index].color"></el-color-picker>
              </el-form-item>
              <el-form-item label="水印透明度" size="mini">
                <el-input-number v-model="coachAdd[index].opacity" :precision="2" :step="0.1" :max="1"></el-input-number>
              </el-form-item>
              <el-form-item label="水印开关" size="mini">
                <el-switch v-model="coachAdd[index].on_off" :active-value="1" :inactive-value="2"></el-switch>
              </el-form-item>
            </div>
            <div class="flex flex-between">
              <el-form-item label="水印字号" size="mini">
                <el-input-number v-model="coachAdd[index].font_size" :precision="0" :step="1" :min="12" :max="40"></el-input-number>
              </el-form-item>
              <el-form-item label="水印纵向间距" size="mini">
                <el-input-number v-model="coachAdd[index].portrait" :precision="0" :step="5" :min="40" :max="1000"></el-input-number>
              </el-form-item>
              <el-form-item label="水印横向间距" size="mini">
                <el-input-number v-model="coachAdd[index].transverse" :precision="0" :step="5" :min="40" :max="1000"></el-input-number>
              </el-form-item>
            </div>
          </div>
          <div class="handle">
            <div class="add" @click="onAdd(index,1)" v-if="showAdd(index,1)"><i class="el-icon-plus"></i></div>
            <div class="remove" @click="onRemove(index,1)" v-if="showRemove(index,1)"><i class="el-icon-delete"></i></div>
          </div>
        </div>
      </div>

    </el-form>
    <div class="buttons">
      <el-button size="mini" type="primary" @click="submit">提交</el-button>
      <!--      <el-button size="mini" @click="reset">重置</el-button>-->
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data(){
    return {
      // 源数据(重置所需)
      sources:[],
      // 原始数据
      coach:[],
      // 新增数据
      coachAdd:[],
      // 校区列表
      schoolList: [],
    }
  },
  computed:{
    dataTotal(){
      return this.coach.length + this.coachAdd.length;
    },
    showAdd(){
      return (index,type)=>{
        let add = false;
        let dLen = this.coach.length;
        let aLen = this.coachAdd.length;
        if (dLen > 0 && aLen === 0){
          add = index === dLen-1;
        }else if(dLen > 0 && aLen > 0){
          if (type === 1)
            add = index === aLen - 1;
        }else if (dLen === 0 && aLen > 0){
          add = index === aLen - 1
        }
        return add;
      }

    },
    showRemove(){
      return (index,type)=>{
        let remove = true;
        let dLen = this.coach.length;
        let aLen = this.coachAdd.length;
        if (dLen + aLen <=1)remove = false;
        return remove;
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    /**
     * @desc 获取初始数据
     */
    getData(){
      this.$_axios2.get("/api/tool/watermark/details").then(res=>{
        let data = res.data;
        if (data.status === 0) {
          this.coach = data.data;
          this.sources = JSON.parse(JSON.stringify(data.data))
        }
        if (data.data.length === 0)this.onAdd();
      }).catch(err=>{
      })
      this.$_axios2.get("/api/tool/dorm/school").then(res=>{
        let data = res.data;
        if (data.status === 0)
          this.schoolList = data.data;
      }).catch(err=>{
      })
    },
    /**
     * @desc 添加校区以及人数事件
     * @param index 操作的数据下标
     * @param type 类型 , 0 已保存, 1 新增的
     */
    onAdd(index,type){
      this.coachAdd.push({
        color: '#F2F1F0',
        opacity: .6,
        on_off: 1,
        font_size: 16,
        portrait: 200,
        transverse: 100
      });
    },
    /**
     * @desc 删除校区及人数事件
     * @param index 操作的数据下标
     * @param type 类 型 , 0 已保存, 1 新增的
     */
    onRemove(index,type){
      if (type === 0){
        this.coach.splice(index,1);
      }else {
        this.coachAdd.splice(index,1);
      }
    },
    /**
     * @desc 提交数据
     */
    submit(){
      let d1 = this.coach,
          d2 = this.coachAdd,
          sr = this.sources,
          message = null;
      let result = [...this.coach];

      let changedOnOff = false;
      if(d1.length === sr.length){
        sr.forEach((item,index) => {
          if(item.on_off !== d1[index].on_off) changedOnOff = true
        })
      }

      if (!d2.length && d1.length === sr.length && !changedOnOff)
        message = "数据无更改,无需提交!"
      for (let item of d2){
        if(!item.school_id) message = '请选择校区信息！'
        if(!item.color) message = '请选择水印颜色！'
      }
      if (message == null){
        result = result.concat(d2)
        this.$_axios2.post("/api/tool/watermark/add",{
          muster:result
        }).then(res=>{
          let data = res.data;
          if (data.status === 0){
            this.coach = this.coach.concat(this.coachAdd);
            this.sources = JSON.parse(JSON.stringify(this.coach));
            this.coachAdd = [];
            this.$message.success({
              message:"保存成功",
              showClose:true
            })
          }else{
            this.$message.error({
              message:data.msg,
              showClose:true
            })
          }
        })
      }else{
        this.$message.warning({
          message:message,
          showClose:true
        })
      }
    },
    /**
     * @desc 重置按钮事件
     */
    reset(){
      if (!this.coach.length)
        this.coachAdd = [{}];
      else
        this.coachAdd = [];
      this.coach = JSON.parse(JSON.stringify(this.sources))
    },
    /**
     * @desc 当校区被选择
     * @param e 选中的值
     * @param index 操作的数据的下标
     */
    onChangeSchool(e,index){
      setTimeout(()=>{
        this.coachAdd[index].school_name = this.$refs.mySelect[0].selectedLabel;
      },20)

      // this.coachAdd[index].school_name = this.$refs.mySelect[0].selectedLabel;
    },
  }

}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item{
  margin-bottom: 0 !important;
}

::v-deep .el-form-item{
  display: flex;
}

.tools{
  position: relative;
  .el-form{

    overflow-y: scroll;
    height: calc(100vh - 64rem - 56rem);
  }
  .data{
    width:100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20rem;
    .el-input-number{
      width: 135rem;
    }
    .item{
      display: flex;
      border-radius: 10rem;
      border:1rem solid #ddd;
      margin-top: 20rem;
      flex:1;
      padding: 10rem;
      align-items: center;
      justify-content: space-between;
      padding-left: 20rem;
      .details{
        float: left;
      }
      .w-25{
        width: 25%;
      }
      .w-33{
        width: 33.33%;
      }
      .handle{
        width:100rem;
        div{
          display: inline-block;
          cursor: pointer;
          font-size: 16rem;
          color: #666;
          border-bottom:1rem solid #eee;
          border-right:1rem solid #eee;
          margin-left: 10rem;
          text-align: center;
          line-height: 40rem;
          width: 40rem;
          height: 40rem;
          border-radius: 20rem;
        }
      }
    }
  }
  .buttons{
    position: absolute;
    padding: 20rem;
    bottom: 0;
    background: white;
    border-top: 1rem solid #eee;
    width:100%;

  }
}

.clear-both:after{
  content: '';
  display: block;
  clear: both;
}
</style>
